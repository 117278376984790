import React from 'react';
import { PrimaryButton } from 'src/components/lib/button';
import 'twin.macro';
import { StyledForm, StyledTextArea, StyledTextField } from './style';

export function Form() {
  return (
    <StyledForm name="submit-bip" data-netlify="true" method="POST" action="/thank-you?type=submit-bip">
      <input type="hidden" name="form-name" value="submit-bip" />
      <div className="grid grid-cols-1 lg:grid-cols-12">
        <div className="col-span-4">
          <StyledTextField required label="First Name*" />
        </div>
        <div className="col-span-4">
          <StyledTextField required label="Last Name*" />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-12">
        <div className="col-span-8">
          <StyledTextField required label="Your Email*" />
        </div>
        <div className="col-span-4">
          <StyledTextField label="Twitter Handle" />
        </div>
      </div>
      <StyledTextArea required chars={5000} label="Description of BIP*" />
      <StyledTextArea required chars={5000} label="Motivation of BIP* (What does it do)" />
      <StyledTextArea required chars={5000} label="BIP Access* (How do people access the BIP)" />
      <StyledTextArea chars={5000} label="Anything else you would like to tell us or include?" />
      <div className="flex justify-end m-5">
        <PrimaryButton className="w-56" type="submit">
          Submit BIP
        </PrimaryButton>
      </div>
    </StyledForm>
  );
}
