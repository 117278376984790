import * as React from 'react';
import { Layout } from 'src/components/layout/layout';
import { SubmitBIP } from 'src/components/submit-bip';

function SubmitBipPage() {
  return (
    <Layout>
      <SubmitBIP />
    </Layout>
  );
}

export default SubmitBipPage;
