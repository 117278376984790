import React from 'react';
import { ContentSection } from 'src/components/layout/layout';
import 'twin.macro';
import { BackLink } from '../lib/navlinks/back';
import { Form } from './form';
import { SubmitTitle } from './style';

export const SubmitBIP = () => {
  return (
    <ContentSection>
      <BackLink />
      <SubmitTitle>Submit a BIP</SubmitTitle>
      <Form />
    </ContentSection>
  );
};
